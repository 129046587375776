<template>
  <div id="topbar" class="d-none d-lg-flex align-items-center fixed-top">
    <div class="container d-flex menuhead">
      <div class="contact-info m-auto d-flex">
        <p class="cyberhead">
          Want a professional website to reflect your company & its vision @
          $149? Reach out
          <a href="mailto:into.cloud@vezham.com">
            <ICON_Mail :size="icon_size" />into.cloud@vezham.com</a
          >
        </p>
      </div>
      <div class="social-links">
        <a :href="whatsapp_api" target="_blank">
          <ICON_Whatsapp :size="icon_size" />
        </a>
        <a
          href="https://www.linkedin.com/company/vezham"
          target="blank"
          class="Linkedin"
        >
          <ICON_Linkedin :size="icon_size" />
        </a>
        <a
          href="https://twitter.com/vezham_vtpl"
          target="blank"
          class="twitter"
        >
          <ICON_Twitter :size="icon_size" />
        </a>
        <a
          href="https://www.facebook.com/Vezham-Technologies-pvt-ltd-111707021515672"
          target="blank"
          class="facebook"
        >
          <ICON_Facebook :size="icon_size" />
        </a>
        <a
          href="https://instagram.com/vezham_vtpl"
          target="blank"
          class="instagram"
        >
          <ICON_Instagram :size="icon_size" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { whatsapp_api } from "@/constants/constant.js";
import ICON_Mail from "@/assets/icons/Mail.vue";
import ICON_Facebook from "@/assets/icons/Facebook.vue";
import ICON_Twitter from "@/assets/icons/Twitter.vue";
import ICON_Instagram from "@/assets/icons/Instagram.vue";
import ICON_Whatsapp from "@/assets/icons/Whatsapp.vue";
import ICON_Linkedin from "@/assets/icons/Linkedin.vue";

export default {
  components: {
    ICON_Mail,
    ICON_Facebook,
    ICON_Twitter,
    ICON_Instagram,
    ICON_Whatsapp,
    ICON_Linkedin,
  },
  data() {
    return {
      icon_size: 24,
      whatsapp_api,
    };
  },
};
</script>

<style scoped>
p.cyberhead,
p.cyberhead a {
  font-size: 14px !important;
}
@media only screen and (min-width: 901px) and (max-width: 1100px) {
  p.cyberhead,
  p.cyberhead a {
    font-size: 12px !important;
  }
}
.menuhead {
  margin: 0px 70px;
}
#topbar {
  background: #000000;
  height: 60px;
  font-size: 14px;
  transition: all 0.5s;
  color: #fff;
  z-index: 99;
}
.contact-info svg {
  margin: 0px 10px;
}
.mr-auto {
  margin-right: auto !important;
}
.social-links svg {
  margin: 0px 10px;
}
i.lni {
  padding: 0px 10px;
  color: #fff;
}
#topbar .contact-info a {
  line-height: 1;
  color: #fff;
  transition: 0.3s;
  font-size: 16px;
  text-decoration: none;
}
</style>
